<!--入库确认-->
<template>
  <div>
    <div class="newForm">
      <div class="M_H">
        <!--<el-button class="seniorbut  " @click="onSubmit">保 存</el-button>-->
        <el-button
          v-if="isn"
          style="background-color: #f0a261; color: #fff; border: none"
          class="ordinarybut"
          @click="saveData"
        >保 存</el-button>
      </div>
      <div class="M">
        <el-form
          ref="WarehousingList"
          :model="WarehousingList"
          :rules="newFormRules"
          label-width="100px"
        >
          <div
            class="inlineDiv"
            v-if="isn"
          >
            <div class="queryItem1 selectInput">
              <el-form-item label="快递单号:">
                <!-- <el-select
                  v-model="expressNum"
                  filterable
                  remote
                  reserve-keyword
                  value-key="courierNumber"
                  placeholder="请输入关键词"
                  :remote-method="remoteMethod"
                  @change="SE"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in expressNumArr"
                    :key="item.courierNumber"
                    :label="item.courierNumber"
                    :value="item"
                  >
                  </el-option>
                </el-select> -->

                <el-input
                  @blur="SelectBlur"
                  @input="remoteMethod"
                  v-model.trim="expressNum"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <div
                class="inputWindow"
                v-if="isShowInput"
              >
                <div class="orderNumbox">
                  <div
                    @click="changeSE(item)"
                    style="margin: 6px 0; cursor: pointer"
                    v-for="(item, index) in expressNumArr"
                    :key="index"
                  >
                    {{ item.courierNumber }}
                  </div>
                </div>
              </div>
            </div>
            <el-button
              style="background-color: #f0a261; color: #fff; border: none"
              class="butstyle"
              @click="QE"
            >查询</el-button>
          </div>
          <div class="inlineDiv">
            <div class="queryItem1">
              <el-form-item label="快递单号:">
                <el-input
                  clearable
                  v-model="WarehousingList.courierNumber"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="会员号:">
                <el-select
                  @change="huiayu"
                  v-model="WarehousingList.memberId"
                  placeholder="请选择会员"
                  clearable
                  filterable
                  remote
                  reserve-keyword
                  :remote-method="getMembers"
                  value-key="memberId"
                >
                  <el-option
                    v-for="(item, index) in ut"
                    :label="item.memberId"
                    :value="item.memberId"
                    :key="index"
                  >
                    {{ item.memberId }} : ({{ item.nickname }})
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="会员名称:">
                <el-input
                  v-model="WarehousingList.nickName"
                  readonly
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="inlineDiv">
            <div class="queryItem1">
              <el-form-item
                label="集运仓库:"
                prop="storageId"
              >
                <el-select
                  v-model="WarehousingList.storageId"
                  @change="selectHq"
                  clearable
                  placeholder="请选择集运仓库"
                >
                  <el-option
                    v-for="item in storageArr"
                    :label="item.storageName"
                    :value="item.id"
                    :key="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item
                label="货物重量kg:"
                prop="packageWeight"
              >
                <el-input v-model="WarehousingList.packageWeight"></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item
                label="件数:"
                prop="boxCount"
              >
                <el-input
                  type="number"
                  v-model="WarehousingList.boxCount"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="inlineDiv">
            <div class="queryItem1">
              <el-form-item
                label="货区:"
                prop="storageCargoareaNumber"
              >
                <el-select
                  v-model="WarehousingList.storageCargoareaNumber"
                  @change="selectHJ"
                  clearable
                  placeholder="请选择仓库"
                >
                  <el-option
                    v-for="(item, index) in goodsArea"
                    :label="item.cargoareaName"
                    :value="item.cargoareaNumber"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item
                label="货架号:"
                prop="storageCargoareaFrameNumber"
              >
                <el-select
                  clearable
                  v-model="WarehousingList.storageCargoareaFrameNumber"
                  placeholder="请选择货区"
                >
                  <el-option
                    v-for="item in goodsBoxNum"
                    :label="item.frameName"
                    :value="item.frameNumber"
                    :key="item.frameNumber"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="queryItem1">
              <el-form-item
                label="长cm:"
                prop="length"
              >
                <el-input
                  placeholder="请输入"
                  v-model="WarehousingList.length"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="inlineDiv">
            <div class="queryItem1">
              <el-form-item
                label="宽cm:"
                prop="wide"
              >
                <el-input
                  placeholder="请输入"
                  v-model="WarehousingList.wide"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item
                label="高cm:"
                prop="height"
              >
                <el-input
                  placeholder="请输入"
                  v-model="WarehousingList.height"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item
                label="包裹备注:"
                prop="packageComment"
              >
                <el-input
                  placeholder="请输入"
                  v-model="WarehousingList.packageComment"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="inlineDiv">
            <div class="queryItem1">
              <el-form-item
                label="入库备注:"
                prop="rukuComment"
              >
                <el-input
                  placeholder="请输入"
                  v-model="WarehousingList.rukuComment"
                ></el-input>
              </el-form-item>
            </div>
            <div
              class="queryItem1"
              style="border: 0"
            >
              <el-form-item
                label=" "
                prop="isError"
                label-width="10px"
              >
                <el-checkbox v-model="WarehousingList.isError">是否加入异常仓库</el-checkbox>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <div class="M">
        <div style="width: 100%">
          <el-table
            border
            :data="WarehousingList.items"
            size="small"
            style="width: 80%"
            :header-cell-style="{ background: '#f0f0f0' }"
          >
            <el-table-column
              label="序号"
              align="center"
              type="index"
              width="60px"
            ></el-table-column>

            <el-table-column
              prop="itemName"
              min-width="150px"
              label="品名"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="WarehousingList.items[scope.$index].itemNameCode"
                  filterable
                  clearable
                  class="inputstyle"
                  @change="changeItem($event, scope.$index)"
                >
                  <el-option
                    v-for="(item, index) in qudaoList"
                    :key="index"
                    :label="item.showName"
                    :value="item.itemNameCode"
                  ></el-option>
                </el-select>
                <el-input
                  v-if="!WarehousingList.items[scope.$index].itemNameCode"
                  v-model="WarehousingList.items[scope.$index].zdyName"
                  placeholder="自定义品名"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              min-width="100px"
              align="center"
              label="数量"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  placeholder="请输入"
                  class="inputstyle"
                  v-model.number="WarehousingList.items[scope.$index].quantity"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="unitPrice"
              min-width="100px"
              align="center"
              label="单价"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  placeholder="请输入"
                  class="inputstyle"
                  v-model.number="WarehousingList.items[scope.$index].unitPrice"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="length"
              min-width="100px"
              align="center"
              label="长/cm"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  placeholder="请输入"
                  class="inputstyle"
                  v-model.number="WarehousingList.items[scope.$index].length"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="width"
              min-width="100px"
              align="center"
              label="宽/cm"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  placeholder="请输入"
                  class="inputstyle"
                  v-model.number="WarehousingList.items[scope.$index].width"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="height"
              min-width="100px"
              align="center"
              label="高/cm"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  placeholder="请输入"
                  class="inputstyle"
                  v-model.number="WarehousingList.items[scope.$index].height"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.$index === 0">
                  <el-button
                    @click="addbtn()"
                    size="mini"
                  >新增</el-button>
                </div>
                <div v-else>
                  <el-button
                    @click="delbut(scope.$index)"
                    size="mini"
                  >删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!--<el-table border-->
          <!--:data="WarehousingList.packageItem"-->
          <!--style="width: 100%"-->
          <!--:header-cell-style="{ background: '#f0f0f0' }">-->
          <!--<el-table-column label="序号" align="center" type="index" width="50">-->
          <!--</el-table-column>-->
          <!--<template v-for="(item, index) in formlist">-->
          <!--<el-table-column :label="item.name"-->
          <!--align="center"-->
          <!--:prop="item.field"-->
          <!--min-width="80px"-->
          <!--:key="index">-->
          <!--</el-table-column>-->
          <!--</template>-->
          <!--</el-table>-->
        </div>
      </div>
      <div class="M">
        <el-upload
          class="upload-demo"
          :action="activeUrl"
          list-type="picture-card"
          :limit="limitCount"
          :accept="'image/*'"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :http-request="uploadSectionFile"
          :file-list="fileList"
          :class="{ hide: hideUpload }"
        >
          <i class="el-icon-plus"></i>
          <div
            class="el-upload__tip"
            slot="tip"
          >
            上传图片
            <!-- 只能上传jpg/png文件，<br />且不超过500kb -->
          </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img
            width="100%"
            :src="dialogImageUrl"
            alt=""
          />
        </el-dialog>
      </div>
    </div>
    <el-dialog
      title="会员管理"
      :visible.sync="isDT"
      width="50%"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <div class="newForm">
        <div class="formS">
          <el-form
            ref="vf"
            size="mini"
            :model="vf"
            :rules="vfr"
            label-width="80px"
          >
            <el-row>
              <el-col
                :span="19"
                style="display: flex"
              >
                <div class="queryItem1">
                  <el-form-item
                    label="会员号:"
                    prop="memberId"
                  >
                    <el-input v-model="vf.memberId"></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="会员名称:"
                    prop="nickname"
                  >
                    <el-input v-model="vf.nickname"></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item
                    label="仓库:"
                    prop="memberId"
                  >
                    <el-select
                      v-model="vf.memberId"
                      placeholder="请选择活动区域"
                    >
                      <el-option
                        label="区域一"
                        value="shanghai"
                      ></el-option>
                      <el-option
                        label="区域二"
                        value="beijing"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="1">
                <el-form-item label-width="10px">
                  <el-button
                    type="warning"
                    @click="cxEdit"
                  >查 询</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Api } from '@/assets/js/api'
import tools from '@/assets/js/tool'
import paging from '@/components/pagings.vue'
// import func from "vue-editor-bridge";

export default {
  components: {
    paging,
  },
  data() {
    return {
      //  查询
      expressNum: '',
      isShowInput: false,
      loading: false,
      expressNumArr: [],
      singleData: {},
      isn: true,
      editId: '', //  包裹Id
      storageArr: [], //  集运仓库数据
      goodsArea: [], //  货区id
      goodsBoxNum: [], //  货架号
      newFormRules: {},
      nickName: '', //会员名称

      WarehousingList: {
        expressName: '', //快递公司名称
        courierNumber: '', //快递单号 必传
        isError: false, //是否加入异常库1=是 0=否 必传
        packageWeight: 0, //包裹重量(kg) 必传
        storageId: '', //仓库id  必传
        boxCount: 1, //包裹件数
        fileNames: [], //照片数组
        height: '', //高
        items: [
          {
            itemName: '', //物品名称
            itemNameCode: '', //渠道物品名称代码
            quantity: '', //数量
            unitPrice: '', //单价
            zdyName: '', //
            length: '',
            width: '',
            height: '',
          },
        ],
        length: '', //长
        memberId: '', //会员号
        nickName: '',
        storageCargoareaFrameNumber: '', //货架编号
        storageCargoareaNumber: '', //货区编号
        wide: '',
        packageComment: '',
        rukuComment: '',
      },
      cangkuList: [], //仓库下拉框
      huojiaList: [], //货架号
      huoquList: [], //货区

      //    会员管理
      isDT: false,
      vf: {},
      vfr: {},
      ut: [],
      pageNum: 1,
      total: 0,
      size: 20,
      sizeList: [10, 20, 50, 100, 200],

      nickname: '',
      qudaoList: [],
      isSelect: false,

      //  上传配置
      limitCount: 10,
      fileList: [],
      activeUrl: '',
      dialogImageUrl: '',
      dialogVisible: false,
      hideUpload: false,
    }
  },
  created() {},
  activated() {
    this.initcc()
  },
  mounted() {
    this.getItemNameList()
    this.getVipList()
  },
  watch: {
    isShowInput: function (newVal, oldVal) {
      this.isSelect = false
    },
  },
  methods: {
    async initcc() {
      if (this.$route.query.packageId) {
        // 判断是否带参数进来
        this.editId = this.$route.query.packageId //  获取到url携带的包裹Id
        await this.getRuKuDetail(this.editId) //  获取入库详情
      } else if (this.$route.query.update) {
        //  修改的时候条件来
        this.editId = this.$route.query.update //  获取到url携带的包裹Id
        this.isn = false
        await this.getRuKuDetail(this.editId) //  获取入库详情
      }
      await this.myGetStorageList() // 获取仓库数组
    },
    changeItem(e, idx) {
      let code = e
      if (!e) {
        this.WarehousingList.items[idx].itemName = ''
      }
      this.qudaoList.forEach((item) => {
        if (item.itemNameCode == code) {
          this.WarehousingList.items[idx].itemName = item.showName
        }
      })
    },
    SelectBlur() {
      let that = this
      setTimeout(() => {
        that.isShowInput = false
        if (that.isSelect) {
          return
        }
        that.WarehousingList.courierNumber = that.expressNum
      }, 300)
    },
    // 选择会员
    huiayu(e) {
      this.ut.forEach((item) => {
        if (e == item.memberId) {
          this.WarehousingList.nickName = item.nickname
          this.getStorageCargoareaIdArr()
        }
      })
    },
    //获取渠道物品名称
    getItemNameList() {
      Api.getItemNameList().then((res) => {
        this.qudaoList = res.data.result.data || []
      })
    },
    //    --------- 入库确认 ---------
    myGetStorageList() {
      //  获取仓库数组数据
      // Api.loginUserStorageDownList().then((res) => {
      //   if (res.data.status === "success") {
      //     this.storageArr = res.data.result || [];
      //     if (this.storageArr.length > 0) {
      //       this.WarehousingList.storageId = this.storageArr[0].storageId;
      //       this.getStorageCargoareaIdArr();
      //     }
      //   }
      // });
      return Api.getStorageList().then((res) => {
        if (res.data.status === 'success') {
          this.storageArr = res.data.result || []
          if (this.storageArr.length > 0 && !this.WarehousingList.storageId) {
            this.WarehousingList.storageId = this.storageArr[0].id
            this.getStorageCargoareaIdArr()
          }
        } else {
          this.$message.error(`${res.data.message}--仓库下拉列表API`)
        }
      })
    },
    getRuKuDetail() {
      //  获取入库详情
      return Api.ruKuDetail({ packageId: this.editId }).then((res) => {
        if (res.data.status === 'success') {
          let array = res.data.result

          //回显品名
          let itemReqS = {
            itemName: '', //物品名称
            itemNameCode: '', //渠道物品名称代码
            quantity: '', //数量
            unitPrice: '', //单价
            zdyName: '', //
            length: '',
            width: '',
            height: '',
          }
          let pckArrs = array.packageItem || []
          console.log(pckArrs)
          console.log('pckArrs')
          let CArrList = pckArrs || []
          for (var c = 0; c < CArrList.length; c++) {
            let flag = false
            for (var d = 0; d < this.qudaoList.length; d++) {
              if (CArrList[c].itemName == this.qudaoList[d].showName) {
                CArrList[c].itemNameCode = this.qudaoList[d].itemNameCode
                flag = true
              }
            }
            if (!flag) {
              CArrList[c].zdyName = CArrList[c].itemName
              CArrList[c].itemName = ''
            }
          }
          if (CArrList.length == 0) {
            CArrList = [itemReqS]
          }

          this.nickName = array.nickName

          // this.WarehousingList = array;
          // this.WarehousingList = {
          this.WarehousingList.expressName = '' //快递公司名称
          this.WarehousingList.courierNumber = array.courierNumber || '' //快递单号 必传
          this.WarehousingList.isError = false //是否加入异常库1=是 0=否 必传
          this.WarehousingList.packageWeight = array.packageWeight || 0 //包裹重量(kg) 必传
          this.WarehousingList.wide = array.wide || '' //宽度
          this.WarehousingList.boxCount = array.boxCount || 1 //包裹件数
          this.WarehousingList.fileNames = [] //照片数组
          this.WarehousingList.height = array.height || '' //高
          this.WarehousingList.items = CArrList
          this.WarehousingList.length = array.length || '' //长
          this.WarehousingList.memberId = array.memberId || '' //会员号
          this.WarehousingList.nickName = array.nickName || '' //会员号
          this.WarehousingList.packageComment = array.packageComment || '' //
          this.WarehousingList.rukuComment = array.rukuComment || '' //

          this.WarehousingList.storageCargoareaFrameNumber = '' //货架编号
          this.WarehousingList.storageCargoareaNumber = '' //货区编号
          if (array.storageId) {
            this.WarehousingList.storageId = array.storageId || '' //仓库id
          }

          // };
          if (
            res.data.result.storageId !== null ||
            res.data.result.storageId !== ''
          ) {
            this.getStorageCargoareaIdArr()
          }

          //回显图片 array.picUrl
          let picList = array.picUrl || []
          let newPicList = []
          picList.forEach((item) => {
            let objs = {
              name: item.fileId,
              url: item.url,
            }
            newPicList.push(objs)
          })
          this.fileList = newPicList
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 远程搜索会员
    getMembers(e) {
      this.getVipList(e)
    },
    remoteMethod(query) {
      //  select框远程搜索
      if (query) {
        this.isShowInput = true
        this.loading = true
        Api.getExpressNumData({ courierNumber: query }).then((res) => {
          if (res.data.status === 'success') {
            this.loading = false
            this.expressNumArr = res.data.result
          }
        })
      } else {
        this.isShowInput = false
        this.expressNumArr = []
      }
    },
    keyUpinput() {
      this.QE()
    },
    QE() {
      //  查询按钮
      this.editId = this.singleData.packageId
      this.getRuKuDetail()
    },
    SE(val) {
      //  快递单号下拉框选中函数
      this.singleData = val
      this.QE()
    },
    // 选择提示的快递单号
    changeSE(val) {
      this.isSelect = true
      this.isShowInput = false
      this.singleData = val
      this.expressNum = val.courierNumber

      this.QE()
    },

    //   -------- 编辑会员 -----------
    changeVip() {
      //  选择会员名称
      this.getVipList()
      this.isDT = true
    },

    getVipList(e) {
      //  获取会员列表
      // this.vf.pageStart = this.pageNum;
      // this.vf.pageTotal = this.size;
      // Api.getMemberList(this.vf).then((res) => {
      //   if (res.data.status === "success") {
      //     this.ut = res.data.result.data;
      //     this.total = res.data.result.pageCount || 0;
      //   }
      // });

      Api.getMemberDownList({ memberId: e || '' }).then((res) => {
        if (res.data.status === 'success') {
          this.ut = res.data.result.data || []
          // this.total = res.data.result.pageCount || 0;
        }
      })
    },
    cxEdit() {
      //  查询按钮
      this.pageNum = 1
      this.getVipList()
    },
    // handleSizeChange() {
    //   //  分页事件
    //   this.size = val;
    //   this.getVipList();
    // },
    // handleCurrentChange() {
    //   //  分页事件
    //   this.pageNum = val;
    //   this.getVipList();
    // },

    xz(data) {
      //  会员表格选择事件
      this.WarehousingList.memberId = data.memberId
      this.nickName = data.nickName
      this.WarehousingList.storageId = data.storageId
      this.isDT = false
    },
    handleClose() {
      //  会员弹出框关闭前回调事件
      this.resetForm('vf')
    },
    resetForm(formName) {
      //  公共表单重置事件
      this.$refs[formName].resetFields()
    },
    //  ----------- 图片上传 --------------
    // 上传图片
    uploadSectionFile(param) {
      const file = param.file
      const fileName = file.name
      const form = new FormData() // 根据后台需求数据格式
      form.append('file', file) // 文件对象
      form.append('fileName', fileName) // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      let signArr = [
        {
          key: 'fileName',
          val: fileName,
        },
      ]
      let sign = tools.getMD5Sign(signArr)
      form.append('sign', sign)
      Api.uploadFile(form).then((res) => {
        if (res.data.status === 'success') {
          this.fileList.push({
            name: res.data.result.fileName,
            uid: res.data.result.redirectUrl,
            url: res.data.result.url,
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    //  图片上传事件
    handleRemove(file, fileList) {
      /* 移除图片 */
      this.fileList = fileList
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    saveData() {
      //  保存数据

      this.WarehousingList.isError == true
        ? (this.WarehousingList.isError = 1)
        : (this.WarehousingList.isError = 0)
      let fileArr = []
      this.fileList.forEach((item) => {
        fileArr.push(item.name)
      })
      this.WarehousingList.fileNames = fileArr

      let newForm = JSON.parse(JSON.stringify(this.WarehousingList)) || []
      for (var a = 0; a < newForm.items.length; a++) {
        if (!newForm.items[a].itemName && newForm.items[a].zdyName) {
          newForm.items[a].itemName = newForm.items[a].zdyName
          newForm.items[a].itemNameCode = ''
        }
      }
      let arrItes = newForm || []
      for (var i = 0; i < arrItes.items.length; i++) {
        delete arrItes.items[i].zdyName
        if (arrItes.items[i].itemName == '') {
          arrItes.items.splice(i, 1)
          i--
        }
      }

      if (!this.WarehousingList.packageWeight) {
        this.$message.warning('货物重量不能为0')
        return false
      }
      let parm = {
        boxCount: this.WarehousingList.boxCount || '',
        courierNumber: this.WarehousingList.courierNumber || '',
        expressName: this.WarehousingList.expressName || '',
        fileNames: this.WarehousingList.fileNames || '',
        height: this.WarehousingList.height || '',
        isError: this.WarehousingList.isError || 0,
        items: arrItes.items || '',
        length: this.WarehousingList.length || '',
        memberId: this.WarehousingList.memberId || '',
        packageWeight: this.WarehousingList.packageWeight || '',
        storageCargoareaFrameNumber:
          this.WarehousingList.storageCargoareaFrameNumber || '',
        storageCargoareaNumber:
          this.WarehousingList.storageCargoareaNumber || '',
        storageId: this.WarehousingList.storageId || '',
        wide: this.WarehousingList.wide || '',
        packageComment: this.WarehousingList.packageComment || '',
        rukuComment: this.WarehousingList.rukuComment || '',
      }
      parm.sign = tools.getSign(parm)
      let btnType = this.Tools.isDaiGou() || false
      if (btnType) {
        //  八米购代购
        Api.bmg_ruKuPackage(parm).then((res) => {
          if (res.data.status === 'success') {
            this.$message.success(res.data.message).showClose = true
            this.WarehousingList.expressName = '' //快递公司名称
            this.WarehousingList.courierNumber = '' //快递单号 必传
            this.WarehousingList.isError = false //是否加入异常库1=是 0=否 必传
            this.WarehousingList.packageWeight = 0 //包裹重量(kg) 必传
            this.WarehousingList.wide = '' //宽度
            this.WarehousingList.boxCount = 1 //包裹件数
            this.WarehousingList.fileNames = [] //照片数组
            this.WarehousingList.height = '' //高
            this.WarehousingList.items = [
              {
                itemName: '', //物品名称
                itemNameCode: '', //渠道物品名称代码
                quantity: '', //数量
                unitPrice: '', //单价
                zdyName: '', //
                length: '',
                width: '',
                height: '',
              },
            ]
            this.WarehousingList.length = '' //长
            this.WarehousingList.memberId = '' //会员号
            this.WarehousingList.nickName = '' //会员
            this.WarehousingList.storageCargoareaFrameNumber = '' //货架编号
            this.WarehousingList.storageCargoareaNumber = '' //货区编号

            this.fileList = []
          } else {
            this.$message.error(res.data.message)
            return
          }
        })
      } else {
        // 下面是原来的
        Api.ruKuPackage(parm).then((res) => {
          if (res.data.status === 'success') {
            this.$message.success(res.data.message)
            this.WarehousingList.expressName = '' //快递公司名称
            this.WarehousingList.courierNumber = '' //快递单号 必传
            this.WarehousingList.isError = false //是否加入异常库1=是 0=否 必传
            this.WarehousingList.packageWeight = 0 //包裹重量(kg) 必传
            this.WarehousingList.wide = '' //宽度
            this.WarehousingList.boxCount = 1 //包裹件数
            this.WarehousingList.fileNames = [] //照片数组
            this.WarehousingList.height = '' //高
            this.WarehousingList.items = [
              {
                itemName: '', //物品名称
                itemNameCode: '', //渠道物品名称代码
                quantity: '', //数量
                unitPrice: '', //单价
                zdyName: '', //
                length: '',
                width: '',
                height: '',
              },
            ]
            this.WarehousingList.length = '' //长
            this.WarehousingList.memberId = '' //会员号
            this.WarehousingList.nickName = '' //会员
            this.WarehousingList.storageCargoareaFrameNumber = '' //货架编号
            this.WarehousingList.storageCargoareaNumber = '' //货区编号

            this.fileList = []
          } else {
            this.$message.error(res.data.message)
            return
          }
        })
      }
    },
    getStorageCargoareaIdArr() {
      //  获取货区id
      Api.getStorageCargoareaChooseList({
        storageId: this.WarehousingList.storageId,
        memberId: this.WarehousingList.memberId || '',
      }).then((res) => {
        if (res.data.status === 'success') {
          this.goodsArea = res.data.result
          if (this.goodsArea.length > 0) {
            this.WarehousingList.storageCargoareaNumber =
              this.goodsArea[0].cargoareaNumber
          }
          if (
            this.WarehousingList.storageCargoareaNumber !== null ||
            this.WarehousingList.storageCargoareaNumber !== ''
          ) {
            this.getFrameNum(this.WarehousingList.storageCargoareaNumber)
          }
        } else {
          console.log(`${res.data.message}--获取货区`)
        }
      })
    },
    getFrameNum(val) {
      //  获取货架id
      let index =
        this.goodsArea.findIndex((v) => v.cargoareaNumber === val) || 0
      let ids = ''
      if (this.goodsArea.length > 0 && this.goodsArea[index]) {
        ids = this.goodsArea[index].id
      }
      Api.getStorageCargoareaFrameChooseList({
        storageCargoareaId: ids,
      }).then((res) => {
        if (res.data.status === 'success') {
          this.goodsBoxNum = res.data.result
          // 默认货架
          // this.WarehousingList.storageCargoareaFrameNumber =
          //   this.goodsBoxNum[0].frameNumber;
        } else {
          console.log(`${res.data.message}--获取货架`)
        }
      })
    },
    selectHq(val) {
      //  选择仓库时候变化
      this.getStorageCargoareaIdArr()
    },
    selectHJ(val) {
      //  选择获区时候变化
      if (val) {
        this.getFrameNum(val)
      } else {
        this.WarehousingList.storageCargoareaFrameNumber = ''
      }
    },

    addbtn() {
      this.WarehousingList.items.push({
        itemName: '',
        itemNameCode: '',
        quantity: 0,
        unitPrice: '',
        zdyName: '',
        length: '',
        width: '',
        height: '',
      })
    },

    delbut(index) {
      //  删除类型
      this.WarehousingList.items.splice(index, 1)
    },
  },
}
</script>

<style scoped lang="scss">
.M_H {
  width: 100%;
  display: flex;
}

.queryItem1 {
  width: 30%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  margin: 3px 1%;
  align-items: center;
  font-size: 13px;
}

.formS {
  border-bottom: 1px silver solid;
  padding-bottom: 20px;
}

.tableBox {
  width: 80%;
  padding: 20px 60px;
}

.M {
  margin-bottom: 20px;
}

.inputstyle ::v-deep {
  .el-input__inner {
    border: 1px solid #d7d7d7 !important;
    // border: 1px solid #f0a261 !important;
  }
}
.butstyle {
  height: 30px;
  margin-top: 3px;
  margin-left: 8px;
  line-height: 8px;
}

.selectInput {
  position: relative;

  .inputWindow {
    width: 100%;
    position: absolute;
    z-index: 99;
    background-color: #fff;
    border: 1px solid #f0a261;
    top: 34px;
    left: -1px;
    .orderNumbox {
      width: 100%;
      min-height: 200px;
      max-height: 380px;
      padding: 0 10px;
      box-sizing: border-box;
      overflow-y: auto;
    }
  }
}
</style>
